* {
  font-family: Roboto;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  background-color: #2b3e50;
  padding: 5px 0;
}

.titlePage { margin-bottom: 20px }

.all_templates h2 { margin-top: 20px }
.theme_view { color: #FFF !important }
.all_templates h1 { margin-bottom: 10px }
.all_templates p { margin-bottom: 10px }

.gridTemplates { padding-top: 20px;}
.gridTemplates h3 { margin: 10% 0;}
.gridTemplates .gridItem { position: relative;}
.gridTemplates .gridItem .contentTemplate { display: none; background-color: rgba(69, 39, 164, 0.7); position: absolute; width: 100%; height: 100%; top: 0; color: #FFF; }
.gridTemplates .gridItem:hover .contentTemplate { display: block;}

.all_templates, .url { display: none;}
.new-customer { width: 50%; margin: 0 auto; }
/*@media (min-width: 768px) {
  .new-customer { width: 100% !important; }
}*/
.bigField { width: 100%; margin-top: 10px !important; }
.middleField { width: 50% }
.submit { margin-top: 20px !important }